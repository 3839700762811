<template>
  <modal-bulk-booking
    v-if="isOpenBulkBooking"
    :onClose="onCloseUploadBulkBooking"
    v-model:pagination="pagination"
    @request="onRequestFetchBulkBookingList"
  />
  <div
    @keydown.esc="onCloseUploadBulkBooking"
    class="pt-4 bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative w-full"
  >
    <div class="flex items-center justify-between my-4 mb-2">
      <div class="flex">
        <Search
          :onInput="onSearch"
          :onClear="clearSearch"
          :value="searchValue"
          placeholder="Cari Upload ID/Nama Aktivitas"
          class="w-2/5"
        />
        <div class="w-1/3 mr-2 items-center">
          <SelectSearch
            :options="posClientList"
            @filter="fetchPosClient"
            @focus="() => fetchPosClient('')"
            :isLoading="isLoadingPosClient"
            @change="onRequestFetchBulkBookingList"
            keyName="name"
            keyValue="id"
            v-model="clientName"
            placeholder="Pilih client/partner/Internal"
          />
        </div>
        <Select
          :dataSelect="statusData"
          :onOpenSelect="onOpenStatusSelect"
          :onCloseSelect="onCloseStatusSelect"
          :onChooseSelect="onSelectStatus"
          :value="statusName"
          :isOpenMenu="statusSelect"
          :isDefaultBlack="true"
          class="w-40"
        />
      </div>
      <div class="flex">
        <lp-button
          @click="download"
          title="Download Template"
          icon="download-gray"
          textColor="gray-lp-300"
          color="gray-lp-400"
          outline
          class="mr-4 whitespace-nowrap"
        />
        <lp-button
          @click="onOpenUploadBulkBooking"
          title="Upload Bulk Booking"
          textColor="white"
          class="whitespace-nowrap"
        />
      </div>
    </div>
    <EmptyState data="Bulk Booking" v-if="isEmpty" />
    <div v-else class="overflow-auto">
      <TableV2
        v-model:pagination="pagination"
        :loading="isLoading"
        :columns="columns"
        :data="dataTable"
        class="my-2"
        :errorCause="errorCause"
        @request="onRequestFetchBulkBookingList"
        :borderTop="false"
        :totalColumnFreeze="1"
        pinnedSubstractHeight="300px"
        paginationStyle="v3"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import ModalBulkBooking from "../component/modal/add.vue";
import { Options, Vue } from "vue-class-component";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import {
  downloadCsv,
  formatDateWithoutTime,
  formatPriceIDR
} from "@/app/infrastructures/misc/Utils";
import { BulkBookingController } from "@/app/ui/controllers/BulkBookingController";
import { PosClientController } from "@/app/ui/controllers/PosClientController";
import debounce from "lodash/debounce";
import flatten from "lodash/flatten";
import JsBarcode from "jsbarcode";
import generatePDF from "@/app/ui/modules/receipt-v2/bulk-resi-pdf/index";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import {
  payloadOfThermalData,
  downloadFilePrn
} from "@/app/infrastructures/misc/UtilsThermal";
import { GTMCommonEvent } from "@/app/infrastructures/misc/gtm-event/common-event";

@Options({
  components: {
    ModalBulkBooking,
    EmptyState
  }
})
export default class BulkBookingTabs extends Vue {
  template = [
    {
      manual_stt_number: "",
      origin_district_name: "",
      destination_district_name: "",
      destination_postal_code: "",
      shipper_name: "",
      shipper_address: "",
      shipper_phone: "",
      consignee_name: "",
      consignee_address: "",
      consignee_phone: "",
      total_pieces: "",
      total_weight: "",
      commodity_code: "",
      product_name: "",
      insurance_service_type: "basic/free/premium",
      value_of_goods: "",
      external_number: "",
      cod: "yes/no",
      woodpacking: "yes/no",
      dfod: "yes/no"
    }
  ];

  clientName = "";

  async download() {
    MainAppController.showLoading();
    const XLSX = await import("xlsx");
    const data = XLSX.utils.json_to_sheet(this.template);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, data, "bulk_booking");
    XLSX.writeFile(wb, "template.xlsx");
    MainAppController.closeLoading();
    GTMCommonEvent("client_template_submenu");
  }

  onOpenUploadBulkBooking() {
    BulkBookingController.setIsOpenUploadBulkBooking(true);
    GTMCommonEvent("upload_bulk_booking_clicked");
  }

  onCloseUploadBulkBooking() {
    BulkBookingController.setFormAddBulkBooking({
      type: "Bulk Booking",
      activityName: "",
      archiveFile: "",
      clientName: ""
    });
    BulkBookingController.setIsOpenUploadBulkBooking(false);
  }

  get isOpenBulkBooking() {
    return BulkBookingController.IsOpenUploadBulkBooking;
  }

  mounted() {
    this.fetchPosClient("");
    this.onRequestFetchBulkBookingList();
    BulkBookingController.setFirstRequest(true);
  }

  onRequestFetchBulkBookingList() {
    BulkBookingController.fetchBulkBookingList({
      search: this.searchValue,
      createdId: this.clientName?.id || "",
      createdType: this.clientName?.type || "",
      status: BulkBookingController.status
    });
  }

  get isFirstRequest() {
    return BulkBookingController.isFirstRequest;
  }

  get errorCause() {
    return BulkBookingController.errorCause;
  }

  // filter status
  statusSelect = false;
  statusData = [
    { name: "Semua Status", value: "" },
    { name: "Success", value: "success" },
    { name: "Partially", value: "partially" },
    { name: "Failed", value: "failed" },
    { name: "Uploading", value: "uploading" }
  ];
  onOpenStatusSelect() {
    this.statusSelect = true;
  }
  onCloseStatusSelect() {
    this.statusSelect = false;
  }
  statusName = "";
  status = "";
  onSelectStatus(name, value) {
    BulkBookingController.selectStatus(value);
    this.onRequestFetchBulkBookingList();
    this.onCloseStatusSelect();
    this.statusName = name;
    this.status = value;
  }

  get isEmpty() {
    return (
      !BulkBookingController.status &&
      !this.dataTable.length &&
      this.isFirstRequest &&
      !this.isLoading
    );
  }

  get loadingCommodity() {
    return PosClientController.isLoading;
  }

  get dataTable() {
    return BulkBookingController.bulkBookingList.data;
  }

  get resiListData() {
    return BulkBookingController.resiListData;
  }

  get isLoading() {
    return BulkBookingController.isLoading;
  }

  // Pagination Table
  get pagination() {
    return BulkBookingController.bulkBookingList.pagination;
  }
  // Table
  columns = [
    {
      name: "Upload ID",
      styleHead: "w-40 text-left whitespace-no-wrap",
      render: item => {
        return `<div class='text-left text-black-lp-300 flex justify-center'>${item.uploadId}</div>`;
      }
    },
    {
      name: "Nama Aktivitas",
      styleHead: "w-56 text-left whitespace-no-wrap",
      render: item => {
        return `<div class="overflow-ellipsis text-black-lp-300">${item.activityName}</div>`;
      }
    },
    {
      name: "Tipe Berkas",
      styleHead: "w-40 text-left whitespace-no-wrap",
      render: item => {
        return `<div class="overflow-ellipsis text-black-lp-300">${item.documentType}</div>`;
      }
    },
    {
      name: "Dibuat Untuk",
      styleHead: "w-56 text-left whitespace-no-wrap",
      render: item => {
        return `<div class='flex text-black-lp-300'>${item.createdFor}</div>
        <div class='flex text-gray-lp-500 text-12px'>${item.createdForCode}</div>`;
      }
    },
    {
      name: "Tanggal Dibuat",
      styleHead: "w-40 text-left whitespace-no-wrap",
      render: item => {
        return `<span class="text-black-lp-300">${formatDateWithoutTime(
          item.createdAt
        )}</span>
        <div class='flex text-gray-lp-500 text-12px'>${item.createdBy}</div>`;
      }
    },
    {
      name: "Total STT",
      styleHead: "w-40 text-left whitespace-no-wrap",
      render: item => {
        return `<div class="overflow-ellipsis text-left capitalize">${item.totalSttSuccess}/${item.totalStt}</div>`;
      }
    },
    {
      name: "Status",
      styleHead: "w-40 text-left whitespace-no-wrap",
      render: item => {
        if (item.status.toLowerCase() === "success") {
          return `<div class="flex text-center font-medium capitalize">
                    <div class="rounded-full w-24 px-2 py-0 bg-green-lp-100 text-white">${item.status}</div>
                  </div>`;
        } else if (item.status.toLowerCase() === "failed") {
          return `<div class="flex font-medium text-center capitalize">
                    <div class="rounded-full w-24 px-2 py-0 bg-red-lp-750 text-white">${item.status}</div>
                  </div>`;
        } else if (item.status.toLowerCase() === "uploading") {
          return `<div class="flex font-medium text-center capitalize">
                    <div class="rounded-full w-24 px-2 py-0 bg-yellow-lp-300 text-white">${item.status}</div>
                  </div>`;
        }
        return `<div class="flex font-medium text-center capitalize">
                    <div class="rounded-full w-24 px-2 py-0 bg-yellow-lp-100 text-white">${item.status}</div>
                </div>`;
      }
    },
    {
      name: "Action",
      key: "button_column",
      styleHead: "w-50 text-left",
      styleButton: (item, index) => {
        if (item.status.toLowerCase() === "success") {
          return {
            outline: true,
            icon: "chevron-down-black",
            title: "More",
            textColor: "black",
            color: "gray-lp-400",
            small: false,
            groupOptionsClass: "pickup-manifest-group-options",
            class: `w-full icon-chevron-down ${
              this.chevronUp[index] ? "" : "icon-chevron-up"
            }`,
            clickFunction: () => {
              this.chevronUp[index] = !this.chevronUp[index];
            },
            groupOptions: [
              {
                icon: "printer",
                label: "Bulk Print Resi Thermal",
                clickFunction: () => {
                  this.printResiA6(item.uploadId);
                }
              },
              {
                icon: "invoice",
                label: "Bulk Print Resi Basic",
                clickFunction: () => {
                  this.printResiA4(item.uploadId);
                }
              }
            ]
          };
        } else if (item.status.toLowerCase() === "partially") {
          return {
            outline: true,
            icon: "chevron-down-black",
            title: "More",
            textColor: "black",
            color: "gray-lp-400",
            small: false,
            groupOptionsClass: "pickup-manifest-group-options",
            class: `w-full icon-chevron-down ${
              this.chevronUp[index] ? "" : "icon-chevron-up"
            }`,
            clickFunction: () => {
              this.chevronUp[index] = !this.chevronUp[index];
            },
            groupOptions: [
              {
                icon: "upload-gray",
                label: "Booking Ulang",
                clickFunction: () => this.onReuploadBulkBooking(item)
              },
              {
                icon: "download-gray",
                label: "Download Failed File",
                clickFunction: () => {
                  BulkBookingController.downloadFailedFile(item.uploadId);
                }
              },
              {
                icon: "download-gray",
                label: "Download Success File",
                clickFunction: async () =>
                  downloadCsv({
                    fileName: `${item.uploadId}_bulk_booking_success.csv`,
                    fields: [
                      "No",
                      "No. Stt",
                      "Origin District Code",
                      "Destination District Code",
                      "Shipper Name",
                      "Shipper Address",
                      "Shipper Phone",
                      "Consignee Name",
                      "Consignee Address",
                      "Consignee Phone",
                      "Total Pieces",
                      "Total Weight",
                      "Commodity Code",
                      "Product Name",
                      "Insurance Service Type",
                      "Value of Goods",
                      "External Number",
                      "COD",
                      "Woodpacking",
                      "Biaya Pengiriman",
                      "Biaya Asuransi",
                      "Surcharge",
                      "Total Biaya Pengiriman",
                      "Biaya Masuk",
                      "PPN",
                      "PPH"
                    ],
                    listStt: await this.mappingSuccessCsv(item)
                  })
              },
              {
                icon: "download-gray",
                label: "Download Original File",
                clickFunction: () => {
                  window.location.href = item.archiveFile;
                }
              }
            ]
          };
        }
        return {
          outline: true,
          icon: "chevron-down-black",
          title: "More",
          textColor: "black",
          color: "gray-lp-400",
          small: false,
          groupOptionsClass: "pickup-manifest-group-options",
          class: `w-full icon-chevron-down ${
            this.chevronUp[index] ? "" : "icon-chevron-up"
          }`,
          clickFunction: () => {
            this.chevronUp[index] = !this.chevronUp[index];
          },
          groupOptions: [
            {
              icon: "upload-gray",
              label: "Booking Ulang",
              clickFunction: () => this.onReuploadBulkBooking(item)
            },
            {
              icon: "download-gray",
              label: "Download Failed File",
              clickFunction: () => {
                BulkBookingController.downloadFailedFile(item.uploadId);
              }
            },
            {
              icon: "download-gray",
              label: "Download Original File",
              clickFunction: () => {
                window.location.href = item.archiveFile;
              }
            }
          ]
        };
      }
    }
  ];
  chevronUp = [];

  async onReuploadBulkBooking(item) {
    const res = await PosClientController.getPosClientList(item.createdFor);
    const clientName = res.list.find(e => e.name === item.createdFor);

    if (clientName) {
      BulkBookingController.setFormAddBulkBooking({
        type: "Bulk Booking",
        activityName: item.activityName,
        archiveFile: "",
        clientName: clientName
      });
      this.onOpenUploadBulkBooking();
    }
  }

  // mapping success csv
  async mappingSuccessCsv(data) {
    const bulkSuccess = await BulkBookingController.getSuccessListBooked(
      Number(data.uploadId)
    );

    const temp = [];
    if (bulkSuccess.sttSuccess.length > 0) {
      bulkSuccess.sttSuccess.map(stt => {
        temp.push({
          No: temp.length + 1,
          "No. Stt": stt.manualSttNumber,
          "Origin District Code": stt.originDistrictCode,
          "Destination District Code": stt.destinationDistrictCode,
          "Shipper Name": stt.shipperName,
          "Shipper Address": stt.shipperAddress,
          "Shipper Phone": stt.shipperPhone,
          "Consignee Name": stt.consigneeName,
          "Consignee Address": stt.consigneeAddress,
          "Consignee Phone": stt.consigneePhone,
          "Total Pieces": stt.totalPieces,
          "Total Weight": stt.totalWeight,
          "Commodity Code": stt.commodityCode,
          "Product Name": stt.productType,
          "Insurance Service Type": stt.insuranceServiceType,
          "Value of Goods": formatPriceIDR(stt.valueOfGoods),
          "External Number": stt.externalNumber,
          COD: stt.cod,
          Woodpacking: stt.woodpacking,
          "Biaya Pengiriman": formatPriceIDR(stt.shippingRate),
          "Biaya Asuransi": formatPriceIDR(stt.insuranceRate),
          Surcharge: formatPriceIDR(stt.totalSurcharge),
          "Total Biaya Pengiriman": formatPriceIDR(stt.totalAmount),
          "Biaya Masuk": formatPriceIDR(stt.incomingRate),
          PPN: formatPriceIDR(stt.ppn),
          PPH: formatPriceIDR(stt.pph)
        });
      });
      return temp;
    }
    return temp;
  }

  get posClientList() {
    const list = PosClientController.posList.list.map(item => {
      return {
        id: item.id,
        name: item.name,
        status: item.status,
        isBanned: item.isBanned,
        type: item.type
      };
    });
    list.length > 0 &&
      list.splice(0, 0, {
        id: 0,
        name: " ",
        status: "",
        isBanned: false,
        type: ""
      });
    return list;
  }

  fetchPosClient = debounce(search => {
    if (search.length > 2 || !search) {
      PosClientController.getPosClientList(search);
    }
  });

  get isLoadingPosClient() {
    return PosClientController.isLoading;
  }

  // search filter
  searchValue = "";
  onSearch(value) {
    this.searchValue = value;
    this.onRequestFetchBulkBookingList();
  }
  clearSearch() {
    this.searchValue = "";
    this.onRequestFetchBulkBookingList();
  }

  // print pdf resi a4
  async printResiA4(sttId) {
    const print = await this.generatePrintData(sttId);
    if (print) {
      this.printDoc();
    }
  }
  async generatePrintData(sttId) {
    return BulkBookingController.getBulkBookingResi({ id: sttId });
  }

  get accountType() {
    return AccountController.accountData.account_type;
  }

  async printDoc() {
    const listSttPieces = [];
    const barcodeList = [];
    const vendorRefCodeList = [];

    BulkBookingController.resiListData.forEach(async (item, index) => {
      // barcode
      const barcode = document.createElement("img");
      const vendorBarcode = document.createElement("img");
      JsBarcode(barcode, item.sttElexysNo || item.sttNo, {
        textAlign: "left",
        displayValue: false,
        fontSize: 16
      });

      // barcode
      let barcodeValue = "";
      if (barcodeList.length > 0) {
        barcodeList.forEach(val => {
          if (val.sttNo === item.sttElexysNo || val.sttNo === item.sttNo)
            barcodeValue = val.barcode;
          else barcodeValue = barcode.src;
        });
      } else barcodeValue = barcode.src;

      barcodeList.push({
        sttNo: item.sttElexysNo || item.sttNo,
        barcode: barcodeValue
      });

      if (item.sttVendorRefferenceNo) {
        // vendor ref code
        JsBarcode(vendorBarcode, item.sttVendorRefferenceNo, {
          textAlign: "left",
          displayValue: false,
          fontSize: 16
        });
      }

      // vendor ref code
      let vendorRefCodeValue = "";
      if (vendorRefCodeList.length > 0) {
        vendorRefCodeList.forEach(val => {
          if (val.sttVendorRefferenceNo === item.sttVendorRefferenceNo)
            vendorRefCodeValue = val.vendorRefCode;
          else vendorRefCodeValue = vendorBarcode.src;
        });
      } else vendorRefCodeValue = vendorBarcode.src;

      vendorRefCodeList.push({
        sttVendorRefferenceNo: item.sttVendorRefferenceNo,
        vendorRefCode: vendorRefCodeValue
      });

      item.sttPieces.forEach(e => {
        listSttPieces.push({
          ...e,
          ...item,
          barcode: barcodeList[index].barcode,
          vendorRefCode: vendorRefCodeList[index].vendorRefCode
        });
      });

      if (index === BulkBookingController.resiListData.length - 1) {
        await generatePDF(this.accountType, listSttPieces);
      }
    });
  }

  // print thermal
  async printResiA6(sttId) {
    const print = await this.generatePrintData(sttId);
    if (print) {
      await this.printThermal();
    }
  }

  async printThermal() {
    const payloadPushed = [];
    const accountType = AccountController.accountData.account_type;
    BulkBookingController.resiListData.forEach((item, index) => {
      payloadPushed.push(
        payloadOfThermalData(item, accountType).map(e => ({
          ...e,
          sttIsCodRetail:
            !item.sttShipmentId &&
            !item.sttClientId &&
            (accountType === "partner" || accountType === "internal") &&
            e.sttIsCod
        }))
      );

      if (index === BulkBookingController.resiListData.length - 1) {
        new Promise(resolve => {
          MainAppController.showLoading();
          setTimeout(resolve.bind(null, null), 1);
        }).then(() => {
          const payloadResult = flatten(payloadPushed);
          downloadFilePrn(payloadResult);
          MainAppController.closeLoading();
        });
      }
    });
  }
}
</script>
